// import { graphql, navigate } from "gatsby";
// import Form, { Field } from "rc-field-form";
import React from "react";
import styled from "styled-components";
import Seo from "../components/SEO";
import { graphql } from "gatsby";
// import { useAsyncFn } from "react-use";

// const InputContainer = styled.div`
//   margin-top: 20px;
//   margin-left: 40px;
//   margin-right: 40px;
// `;
// const StyledInput = styled.input`
//   outline: none;
//   min-width: 100%;
//   height: 31px;
// `;
// const Body = styled.textarea`
//   min-width: 100%;
//   outline: none;
//   height: 200px;
// `;

// const BodyInput = ({ value = "", ...props }) => {
//   return (
//     <InputContainer>
//       <label htmlFor={`Input_${props.label}`}>{props.label}</label>
//       <Body value={value} id={`Input_${props.label}`} {...props} />
//     </InputContainer>
//   );
// };
// const Input = ({ value = "", ...props }) => {
//   return (
//     <InputContainer>
//       <label htmlFor={`Input_${props.label}`}>{props.label}</label>
//       <StyledInput value={value} id={`Input_${props.label}`} {...props} />
//     </InputContainer>
//   );
// };

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  max-width: 80%;
  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: var(--grey) 1px solid;
    border-radius: 0.725rem;
    box-shadow: 1px 2px 1rem var(--grey);
  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  margin: 0;
`;
const Header = styled.header`
  text-align: center;
`;
const Information = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  margin-bottom: 1.75rem;
`;

// const FieldError = styled.span`
//   color: #ff4949;
//   margin: 0;
//   margin-left: 40px;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   align-content: flex-end;
//   margin-top: 30px;
//   margin-bottom: 20px;
//   margin-left: 40px;
// `;

const PhoneNumber = styled.a`
  color: var(--blue);
  font-size: 1.1rem;
`;

// function encode(data) {
//   return Object.keys(data)
//     .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//     .join("&");
// }

export default function Contact({ data }) {
  // const [values, setState] = React.useState({});

  // const handleChange = (e) => {
  //   setState({ ...values, [e.target.name]: e.target.value });
  // };

  const { blurb, street, city, state, zipCode } = data.contact;
  const people = data.people.nodes;
  const formatPhone = (phone) => {
    if (!phone) return "";
    const trimmed = phone.trim().replace("-", "");
    const areaCode = trimmed.slice(0, 3);
    const firstDigits = trimmed.slice(3, 6);
    const lastDigits = trimmed.slice(6);
    return `${areaCode}-${firstDigits}-${lastDigits}`;
  };

  // const [onFinishState, onFinish] = useAsyncFn(async (e) => {
  //   e.preventDefault();
  //   const form = e.target;
  //   await fetch("/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: encode({
  //       "form-name": form.getAttribute("name"),
  //       ...values,
  //     }),
  //   });
  //   navigate("/");
  // .then(() => ))
  // .catch((error) => alert(error));
  // }, []);

  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <Container>
          <Header>
            <Heading>Contact Us</Heading>
            <p>{blurb}</p>
          </Header>
          <Information>
            <h2>Office Location</h2>
            <address>
              {street}
              <br />
              {`${city}, ${state} ${zipCode}`}
            </address>
            <span>
              {people
                .filter(({ phone }) => phone !== null)
                .map((person) => {
                  return (
                    <div key={person.id}>
                      <span>{person.name} - </span>
                      <PhoneNumber href={`tel:${person.phone}`}>
                        {formatPhone(person.phone)}
                      </PhoneNumber>
                    </div>
                  );
                })}
            </span>
          </Information>
          <>
            {/* <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={onFinish}
            >
              <input
                type="hidden"
                name="form-name"
                value="contact"
                disabled={onFinishState.loading}
                onChange={handleChange}
              />
              <p hidden>
                <label>
                  Other
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              <Input
                name="name"
                type="text"
                label="*Name"
                disabled={onFinishState.loading}
                onChange={handleChange}
                value={values["name"]}
              />
              <Input
                name="email"
                type="email"
                label="*Email"
                disabled={onFinishState.loading}
                onChange={handleChange}
                value={values["email"]}
              />
              <Input
                name="phone"
                type="text"
                label="Phone Number"
                disabled={onFinishState.loading}
                onChange={handleChange}
                value={values["phone"]}
              />
              <BodyInput
                name="message"
                label="*Message"
                disabled={onFinishState.loading}
                onChange={handleChange}
                value={values["message"]}
              />
              <ButtonContainer>
                <button type="submit">Send</button>
              </ButtonContainer>
            </form> */}
            {/* <Form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onFinish={onFinish}
            >
              {(_, form) => {
                const emailError = form.getFieldError("email");
                const nameError = form.getFieldError("name").length > 0;
                const messageError = form.getFieldError("message").length > 0;
                return (
                  <>
                    <input
                      type="hidden"
                      name="form-name"
                      value="contact"
                      disabled={onFinishState.loading}
                    />
                    <Field name="name" rules={[{ required: true }]}>
                      <Input label="*Name" disabled={onFinishState.loading} />
                    </Field>
                    {nameError && (
                      <FieldError>Your name is required</FieldError>
                    )}
                    <Field
                      name="email"
                      validateTrigger="onBlur"
                      rules={[{ type: "email" }, { required: true }]}
                    >
                      <Input label="*Email" disabled={onFinishState.loading} />
                    </Field>
                    {emailError && <FieldError>{emailError}</FieldError>}
                    <Field name="phone">
                      <Input
                        label="Phone Number"
                        disabled={onFinishState.loading}
                      />
                    </Field>
                    <Field name="message" rules={[{ required: true }]}>
                      <BodyInput
                        label="*Message"
                        disabled={onFinishState.loading}
                      />
                    </Field>
                    {messageError && (
                      <FieldError>A message is required</FieldError>
                    )}
                    <ButtonContainer>
                      <button type="submit">Send</button>
                    </ButtonContainer>
                  </>
                );
              }}
            </Form> */}
          </>
        </Container>
      </Layout>
    </>
  );
}

export const query = graphql`
  query {
    contact: sanityContact {
      blurb
      city
      state
      street
      zipCode
    }
    people: allSanityPerson {
      nodes {
        id
        name
        phone
      }
    }
  }
`;
